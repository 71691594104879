// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300&display=swap');

@import "../../node_modules/rfs/scss";
@import url('https://fonts.googleapis.com/css?family=Karla&family=Merriweather&display=swap');


// Variables
@import 'variables';

@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
@import '~taggle/src/minimal.css';



// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~jquery-ui/themes/base/all.css';



//animate
//@import "~animate.css/source/_base.css";

// fontawesome
@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss';

@import '~jquery-easy-loading/src/loading.css';
@import '~jquery-confirm/dist/jquery-confirm.min.css';
@import '~toastr/toastr.scss';

:root {
    --color-primary: #0078d7;
    --color-secondary: #5b89ce;
    --color-tertiary: #a59fa3;
}

.navbar-laravel {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

.subtitle {
    @include responsive-font-size(40px);
}

.experience-card {
    border-left: 4px solid var(--color-primary) !important;
    border-right: 4px solid var(--color-primary) !important;
}

.experience-card-date {
    color: var(--color-primary);
}

.skill-col {
    border-left: 4px solid var(--color-primary);
    font-size: 18px;
}

.nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
}


@media all {
    .page-break {
        display: none;
    }
}

@media print {
    .page-break {
        display: block;
        page-break-before: always;
        margin-top: 50px;
    }
}

.required-icon {
    font-size: 8px;
    color: red;
}

body {
    position: relative;
    background-color: #faf9f8;
}

.avatar-small {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.mastfoot {
    color: #636b6f;
    margin-top: auto !important;
    display: block;
    box-sizing: border-box;
    text-align: center !important;
}

.footer a {
    font-weight: bold;
    line-height: 1.5;
    color: #495057;
}

.nav-bar-ms {
    background-color: #0078d7;
}

.link-black:focus,
.link-black:hover {
    color: #000000;
    font-weight: bold;
}

.link-black {
    color: #000000;
}

.link-gray {
    color: #495057;
}

.link-gray:focus,
.link-gray:hover {
    color: #000000;
    font-weight: bold;
}

p {
    font-size: 18px;
}

.brand-cll {
    font-family: 'Karla', sans-serif !important;
}

.shadow-ms {
    box-shadow: rgb(0 0 0 / 10%) 0px 0.3px 0.9px, rgb(0 0 0 / 13%) 0px 1.6px 3.6px;
}


.card-post {
    transition: box-shadow 0.2s ease, -webkit-transform 0.3s cubic-bezier(0.34, 2, 0.6, 1);
    transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1), box-shadow 0.2s ease;
    transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1), box-shadow 0.2s ease,
        -webkit-transform 0.3s cubic-bezier(0.34, 2, 0.6, 1);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.card-post:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
}


.position-image {
    width: auto;
    height: 50px;
}

.stack-image {
    width: auto;
    height: 30px;
}

.card-text-subtitle {
    font-size: 18px;
    font-weight: bold;
}

.landing-image {
    background-image: url("/images/ocean.png");
}

@include media-breakpoint-only(xs) {
    .info-card-name {
        font-size: 18px;
    }

    .info-card-job {
        font-size: 14px;
    }

    .position-image {
        width: auto;
        height: 40px;
    }

    .card-text-subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-only(sm) {
    .info-card-name {
        font-size: 18px;
    }

    .info-card-job {
        font-size: 14px;
    }

    .position-image {
        width: auto;
        height: 40px;
    }
}

@include media-breakpoint-only(md) {
    .info-card-name {
        font-size: 20px;
    }

    .info-card-job {
        font-size: 17px;
    }

    .info-card-bio {
        font-size: 12px;
    }
}

@include media-breakpoint-only(lg) {
    .info-card-name {
        font-size: 11px;
    }

    .info-card-job {
        font-size: 10px;
    }
}

@include media-breakpoint-only(xl) {
    .info-card-name {
        font-size: 13px;
    }

    .info-card-job {
        font-size: 11px;
    }
}