// Body
$body-bg: #ffffff;

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-family-serif: 'Merriweather', serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #0078d7;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66D9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$enable-shadows: true;

$fa-font-path: "../webfonts";

$enable-responsive-font-sizes: true;